<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                {{ $store.state.sysenv.codePropagated.name }}
                <small>Menu Group</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd()"></button>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            item-key="groupSn"
            hide-actions
            draggable
            :headers="headers"
            :items="navGroup"
            @row-move-to="onRowMoved"
            @sorted="onSorted"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus($event,props)" class="sortableRow">
                <td v-if="codePropagated.code === 'ALL'">{{ props.item.phaseName }}</td>
                <td style="text-align: center;" v-if="codePropagated.code !== 'ALL'" class="handle">
                  <button class="jcon_order"></button>
                </td>
                <td style="text-align: center;">{{ props.item.no }}</td>
                <td>{{ props.item.groupCode }}</td>
                <td>{{ props.item.cnName }}</td>                
                <td>{{ props.item.groupName }}</td>
                <td v-if="UpdateVisible" @click="dbTranster(props)">Update</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>

      <j-form-modal
        title="Menu Group"
        ref="formHandler"
        @create="add()"
        @edit="edit()"
        @delete="del()"
        @cancel="cancel()"
        :formMode="formode"
        :resetable="resetable()"
        :opened.sync="modOpen"
        :modalType="'type00'"
      >
        <v-container>
          <v-layout wrap>
            <v-flex xs12 class="wrap__select">
              <v-select
                v-model="selectedItem.phaseCode"
                dense
                required
                label="Phase Title"
                item-text="phaseName"
                item-value="phaseCode"
                placeholder="Select a Phase"
                :menu-props="{contentClass:'single_select'}"
                :rules="defaultRules"
                :items="phaseOptions"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.groupName"
                required
                label="Group Title"
                placeholder="Input/Edit group title"
                :rules="nameRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.cnName"
                required
                label="CN_Name"
                placeholder="Input/Edit Cn_Name"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </j-form-modal>
    </div>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
    <j-snackbar
      :snackbar="snackbar"
      :snackbarColor="'success'"
      :closeBtnColor="'buttonColor'"
      :positionX="'top'"
      :positionY="'right'"
      :top="'115px'"
      :timeout="2000"
      :mode="'mode'"
      :text="'Priority changed'"
    ></j-snackbar>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { SystemService } from "@/services"
import JFormModal from "@/components/floating/JFormModal"
import JSnackbar from "@/components/JSnackbar"

export default {
  name: "sysenv-menu-group",
  components: {
    JFormModal,
    JSnackbar
  },
  data: () => ({
    UpdateVisible: true,
    itemKey: new WeakMap(),
    systemService: null,
    snackbar: false,
    modOpen: false,
    phaseOptions: [],
    permitOptions: [],
    selected: [],
    selectedIndex: -3,
    selectedItem: {},
    selectedPrevItem: [],
    headers: [
      { type: "drag", text: "", align: "right", value: "", width: 30 },
      {
        type: "text",
        text: "Phase Title",
        align: "left",
        value: "phaseName",
        width: 110
      },
      { type: "conf", text: "No", align: "center", value: "no", width: 40 },
      {
        type: "text",
        text: "Code",
        align: "left",
        value: "groupCode",
        width: 100
      },
     {
        type: "text",
        text: "CnName",
        align: "left",
        value: "CnName",
        width: 100
      },      
      { type: "text", text: "Title", align: "left", value: "groupName", width: 150 },
      { type: "text", text: "update", align: "left", value: "update" }
    ],
    navGroup: [],
    loading: false,
    select: null,
    formode: __C.FORM.EDIT_MODE_NEW,
    valid: false,
    defaultRules: [v => !!v || "Required"],
    nameRules: [
      v => !!v || "Required",
      v => (!!v && v.length >= 2) || "At least 2 characters",
      v => (!!v && v.length <= 80) || "Less then 80 characters"
    ],
    pathRules: [
      v =>
        /^[a-zA-Z0-9@/]*$/.test(v) ||
        "Not allowed space and special letters except @ /"
    ],
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => { },
    sortable: null
  }),
  watch: {
    codePropagated: {
      handler(val) {
        this.headersChange(val); // headers 변경
        // to retain or set page's default status in 'Add'
        this.selectedIndex = -3;
        // sortable 작업 후 하위 메뉴 이동시 table 강제로 리로드
        this.navGroup = [{ dummy: "dummy" }];
        // retrieve the list for the new condition
        this.systemService.getNavGroup(val.code, this.setNavGroup);
      },
      deep: true
    }
  },
  created() {
    this.systemService = new SystemService();
  },
  mounted() {
    this.systemService.getNavPhase(res => {
      if (!res) this.phaseOptions = []
      else this.phaseOptions = res

      this.systemService.getCodeIndex("PERMISSION", res => {
        if (!res) this.permitOptions = []
        else res.forEach(item => {
          if (item.sn > 1) this.permitOptions.push({ text: item.name, value: item.code })
        })
        this.systemService.getNavGroup(
          this.codePropagated.code,
          this.setNavGroup
        )
      })
    })
    this.headersChange(this.codePropagated)
  },
  computed: {
    codePropagated: {
      get() {
        return this.$store.state.sysenv.codePropagated;
      },
      set(value) {
        this.$store.commit("sysenv/codePropagate", value);
      }
    },
    menuUpdated: {
      get() {
        return this.$store.state.sysenv.menuUpdated;
      },
      set(value) {
        this.$store.commit("sysenv/setMenuStatusUpdated", value);
      }
    },
  },
  methods: {
    setNavGroup(res) {
      this.modOpen = false;

      if (!res) res = [];

      // 'no' field is generated by v-data-table's items="props" action
      // because of that props.item.no is defined to the data-date' item template
      // it doesn't need to add manually...
      // res.forEach(item => { item['no'] = 0 })
      this.navGroup = res;
      // at the very first time access or page reloading,
      // it comes twise from 'watcher' and 'mounted' event
      if (this.selectedIndex < -1) {
        ++this.selectedIndex;

        // if this action comes from that add new menu group,
        // right added item should be selected manually.
      } else if (this.selectedIndex == -1) {
        // Attention, this is groupName, not the groupCode...
        this.setEdit(
          this.navGroup.findIndex(
            x => x.groupName === this.selectedItem.groupName
          )
        );
      } else {
        // if this action comes from that delete existing menu group,
        // select a forward or backward item.
        if (
          this.navGroup.findIndex(
            x => x.groupCode === this.selectedItem.groupCode
          ) < 0
        ) {
          if (this.navGroup.length > 0) {
            if (this.navGroup.length >= this.selectedIndex + 1) {
              // Nothing to do...
            } else {
              this.selectedIndex = this.navGroup.length - 1;
            }
            this.setEdit(this.selectedIndex);
          } else {
            // 삭제시 등록화면 호출 부분 제거
            // this.setAdd()
          }
        } else {
          this.setEdit(this.selectedIndex);
        }
      }
    },
    onRowMoved(val) {
      const rowSelected = this.navGroup.splice(val.oldIndex, 1)[0]; // Get the selected row and remove it
      this.navGroup.splice(val.newIndex, 0, rowSelected); // Move it to the new index
      this.snackbar = false;
      this.systemService.setPriority(
        {
          newIdex: val.newIndex,
          oldIndex: val.oldIndex,
          targetName: "NAV_GROUP",
          conditionName1: this.codePropagated.code
        },
        () => {
          this.snackbar = true;
          this.menuUpdated = true;
          this.systemService.getNavGroup(
            this.codePropagated.code,
            res => {
            this.navGroup = []
            // Need some time-delay for draggable process
            setTimeout(() => { this.setNavGroup(res) })
          })
        }
      );
    },
    onSorted(items) {
      items.forEach((item, index) => {
        item.no = index + 1;
      });
    },
    dbTranster(props) {
      const _self = this
 
      this.yes = () => {
        this.msgOpen = false
        this.systemService.transferGroup(props.item, res => {
          _self.menuUpdated = true
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Transfer Group."
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to transfer Selected Menu Group?"
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText[0] = "Yes"
      this.msgOpen = true
    },    
    add() {
      const _self = this;
      this.yes = () => {
        _self.msgOpen = false
        let phaseName_ = this.phaseOptions.find(p => p.phaseCode == this.selectedItem.phaseCode).phaseName
        this.selectedItem.urlSelf = `/${this.selectedItem.groupName.toLowerCase().replace(/[\W]+/g, '_')}`
        this.selectedItem.url = (
          `/service` +
          `/${phaseName_.toLowerCase().replace(/[\W]+/g, '_')}` +
          this.selectedItem.urlSelf
        )
        this.selectedItem.type = 'Group'
        this.systemService.putNavGroup(this.selectedItem, res => {
          this.systemService.getNavGroup(
            this.codePropagated.code,
            this.setNavGroup
          );
          _self.menuUpdated = true;
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save new Menu Group?";
      this.msgInfo.button = [true, false, true];
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    edit() {
      const _self = this;
      this.yes = () => {
        _self.msgOpen = false;
        let phaseName_ = this.phaseOptions.find(p => p.phaseCode == this.selectedItem.phaseCode).phaseName
        this.selectedItem.urlSelf = `/${this.selectedItem.groupName.toLowerCase().replace(/[\W]+/g, '_')}`
        this.selectedItem.url = (
          `/service` +
          `/${phaseName_.toLowerCase().replace(/[\W]+/g, '_')}` +
          this.selectedItem.urlSelf
        )
        this.selectedItem.type = 'Group'
        this.systemService.updNavGroup(this.selectedItem, res => {
          this.systemService.getNavGroup(
            this.codePropagated.code,
            this.setNavGroup
          );
          _self.menuUpdated = true;
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message =
        this.selectedItem.phaseCode != this.selectedPrevItem[0].phaseCode
          ? `
        <span style="
          font-style: italic; 
          font-weight: 300; 
          font-size: 12px; 
          color: #f75c02
        ">
          Attention: All of its menu items will also be moved to under the changed Menu Phase.
        </span><br /><br />
        Do you want to updated selected Menu Group?
      `
          : "Do you want to save this changes?";
      this.msgInfo.button = [true, false, true];
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    del() {
      const _self = this;
      this.yes = () => {
        _self.msgOpen = false;
        this.systemService.delNavGroup(
          this.selectedItem.phaseCode,
          this.selectedItem.groupCode,
          res => {
            this.systemService.getNavGroup(
              this.codePropagated.code,
              this.setNavGroup
            );
            _self.menuUpdated = true;
          }
        );
        this.yes = () => { };
      };

      // 삭제하기 전에 삭제하려는 Group 데이터와 연결된 Item 데이터가 있으면 삭제를 못 하도록 설정
      this.systemService.getNavRelated(
        "Item",
        this.selectedItem.groupCode,
        res => {
          if (res == 0) {
            this.msgInfo.type = "WARN";
            this.msgInfo.title = "Action Approval";
            this.msgInfo.titleDescription = "Important Notification";
            this.msgInfo.message = `
            <span style="
              font-style: italic; 
              font-weight: 300; 
              font-size: 12px; 
              color: #f75c02
            ">
              Attention: All of its menu items will be deleted.
            </span><br /><br />
            Do you want to delete selected Menu Group?
          `;
            this.msgInfo.button = [true, false, true];
            this.msgInfo.buttonText = ["Yes", "No", "Cancel"];
            this.msgInfo.buttonText[0] = "Delete";
            this.msgOpen = true;
          } else {
            this.msgInfo.type = "WARN";
            this.msgInfo.title = "Can Not Delete";
            this.msgInfo.titleDescription = "Important Notification";
            this.msgInfo.message =
              "Related Menu Item have " +
              res +
              " of data. " +
              "Please delete relevant data first.";
            this.msgInfo.button = [false, false, true];
            this.msgOpen = true;
          }
        }
      );

      // this.msgInfo.type = 'WARN'
      // this.msgInfo.title = 'Action Approval'
      // this.msgInfo.titleDescription = 'Important Notification'
      // this.msgInfo.message = `
      //   <span style="
      //     font-style: italic;
      //     font-weight: 300;
      //     font-size: 12px;
      //     color: #f75c02
      //   ">
      //     Attention: All of its menu items will be deleted.
      //   </span><br /><br />
      //   Do you want to delete selected Menu Group?
      // `
      // this.msgInfo.buttonText[0] = 'Delete'
      // this.msgOpen = true
    },
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.navGroup.filter(e => {
          return (
            (e.groupName || "").toLowerCase().indexOf((v || "").toLowerCase()) >
            -1
          );
        });
        this.loading = false;
      }, 500);
    },
    propStatus(e, props) {
      if (e.target.textContent == 'Update') this.modOpen = false
      else {
        this.selectedIndex = this.navGroup.indexOf(props.item);
        this.selectedItem = Object.assign({}, props.item);
        if (this.selectedPrevItem.length > 0) {
          if (this.selectedPrevItem[0].idx === props.item.idx) {
            this.modOpen = true;
            return;
          } else {
            let _selected = props.item;
            this.selected = [_selected];
          }
        } else {
          props.selected = !props.selected;
        }
        this.selectedPrevItem = [...this.selected];
        this.formode = __C.FORM.EDIT_MODE_MOD
        this.modOpen = true;
      }
    },
    setAdd() {
      // at the very first time access or page reloading,
      // it comes twise from 'watcher' and 'mounted' event.
      // so it should be retained to be increased by them until being -1.
      this.selectedIndex = this.selectedIndex < -1 ? this.selectedIndex : -1;
      this.selectedItem = {};
      this.selected = [];
      this.selectedPrevItem = [];
      this.formode = __C.FORM.EDIT_MODE_NEW
      this.modOpen = true;
      this.$refs.formHandler.formReset();

      if (this.codePropagated.code !== "ALL") {
        setTimeout(() => {
          this.selectedItem = { phaseCode: this.codePropagated.code };
        });
      }
    },
    setEdit(selectedIndex) {
      // myArray.map(x => x.hello).indexOf('stevie')
      this.selectedIndex = selectedIndex;
      this.selectedItem = Object.assign({}, this.navGroup[this.selectedIndex]);
      this.selected = [Object.assign({}, this.navGroup[this.selectedIndex])];
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
    },
    resetable() {
      if (this.selectedIndex < 0) {
        if (
          (this.codePropagated.code === "ALL" &&
            !!this.selectedItem.phaseCode) ||
          !!this.selectedItem.groupName ||
          !!this.selectedItem.itemPermit ||
          !!this.selectedItem.url ||
          !!this.selectedItem.direction
        )
          return true;
        return false;
      } else {
        if (
          // !!this.selectedPrevItem[0].phaseCode && !!this.selectedPrevItem[0].phaseCode ? this.selectedPrevItem[0].phaseCode != this.selectedItem.phaseCode : false ||
          this.selectedPrevItem[0].phaseCode != this.selectedItem.phaseCode ||
          this.selectedPrevItem[0].groupName != this.selectedItem.groupName ||
          this.selectedPrevItem[0].itemPermit != this.selectedItem.itemPermit ||
          this.selectedPrevItem[0].url != this.selectedItem.url ||
          this.selectedPrevItem[0].direction != this.selectedItem.direction ||
          this.selectedPrevItem[0].cnName != this.selectedItem.cnName
        )
          return true;
        return false;
      }
    },
    reset() {
      if (this.selectedIndex < 0) {
        this.selectedItem = {}
        this.$refs.formHandler.formReset()
        setTimeout(() => {
          this.selectedItem = (
            this.codePropagated.code !== "ALL"
            ? { phaseCode: this.codePropagated.code }
            : {}
          )
        })
      } else {
        this.selectedItem = Object.assign({}, this.selectedPrevItem[0])
      }
    },
    cancel() {
      this.modOpen = false
    },
    isCodeValid(groupCode) {
      return !this.navGroup.find(item => item.groupCode == groupCode)
    },
    headersChange(val) {
      let phaseIndex = this.headers.findIndex(h => h.value === "phaseName")
      let target = `${__C.HOST_NAME_RESOURCE}`
      if (target == 'https://tortue.jingraphics.com/resources' || target == 'https://demo.jingraphics.com/resources') {
       let upIndex = this.headers.findIndex(h => h.text == 'update')
       if (upIndex > -1 ) this.headers.splice(upIndex, 1)
       this.UpdateVisible = false  
      }      
      if (val.code === "ALL") {
        // headers no 변경
        let noIndex = this.headers.findIndex(h => h.value === "no")
        if (noIndex > -1) this.headers[noIndex].value = "groupSn"
        // headers Phase 추가
        if (phaseIndex < 0)
          this.headers.unshift({
            type: "text",
            text: "Phase Title",
            align: "left",
            value: "phaseName",
            width: 110
          })
        // headers dag 제거
        let dragIndex = this.headers.findIndex(h => h.type === "drag")
        if (dragIndex > -1) this.headers.splice(dragIndex, 1)
      } else {
        // headers no 변경
        let snIndex = this.headers.findIndex(h => h.value === "groupSn")
        if (snIndex > -1) this.headers[snIndex].value = "no"
        // headers Phase 제거
        if (phaseIndex >= 0) this.headers.shift();
        // headers dag 추가
        let dragIndex = this.headers.findIndex(h => h.type === "drag")
        if (dragIndex == -1)
          this.headers.unshift({
            type: "drag",
            text: "",
            align: "right",
            value: "",
            width: 30
          })
      }
    }
  }
};
</script>
