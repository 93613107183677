<template>
  <div>
    <v-snackbar
      v-model="_snackbar"
      :style="`top:${top};`"
      :right="positionY === 'right'"
      :left="positionY === 'left'"
      :top="positionX === 'top'"
      :bottom="positionX === 'bottom'"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
      :timeout="timeout"
      :color="snackbarColor"
    >
    <div @click="forceMove">
      {{ text }}
    </div>
      <v-btn
        :color="closeBtnColor"
        flat
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    name: 'j-snackbar',
    computed: {
      _snackbar: {get(){ return !!this.snackbar ? this.snackbar : false }, set(){} },
      _href: { get(){ return !!this.href ? this.href : '#' }, set(){} },
      // _toGo: { get(){ return !!this.toGo && this.toGo.length > 0 ? this.toGo : {}}, set(){} },
      codePropagated: { get(){ return this.$store.state.sysenv.codePropagated }, set(value){ this.$store.commit('sysenv/codePropagate', value) }},
    },
    props: {
      snackbar      : { type: Boolean, default: false         },
      snackbarColor : { type: String,  default: 'info'        }, // value: info, success, error...#fefefe
      closeBtnColor : { type: String,  defalut: 'buttonColor' }, // value: buttonColor, white...#fefefe
      positionX     : { type: String,  defalut: 'top'         }, // value: top, bottom
      positionY     : { type: String,  defalut: ''            }, // value: left, right
      timeout       : { type: Number,  default: 1000          }, // value: 1000, 2000...
      top           : { type: String,  default: ''            }, // value: 100px, 200px...
      mode          : { type: String,  default: ''            }, // value: vertical, multi-line
      text          : { type: String,  default: ''            }, 
      href          : { type: String,  default: '#'           },
      toGo          : { type: Object,  defalut: {}            },
    },
    methods: {
      forceMove : function() {
        if(!!this.href) {
          location.href = this._href
          setTimeout(() => { this.codePropagated = { code: this.toGo.code, name: this.toGo.name }}, 200)
        }
      } 
    }
  }
</script>